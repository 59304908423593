(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Person
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Person', Person);

  function Person($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/persons/:personId', {personId: '@_personId'}, {
      update: {
        method: 'PUT'
      }
    });
  }
}());
